import { render, staticRenderFns } from "./image.vue?vue&type=template&id=323c3592&scoped=true&"
import script from "./image.vue?vue&type=script&lang=ts&"
export * from "./image.vue?vue&type=script&lang=ts&"
import style0 from "./image.vue?vue&type=style&index=0&id=323c3592&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323c3592",
  null
  
)

export default component.exports